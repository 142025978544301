import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: ${({ theme }) => theme.palette.secondary.dark};
`

export const AuthenticationWrapper = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>
}
