import React, { useContext } from 'react'
import { AuthenticationWrapper } from '../components/auth/AuthenticationWrapper'
import Head from 'next/head'
import { AuthenticationModal, MagicConnectUserContext } from '@fuel/auth'
import router from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

const LoginPage = () => {
  const { userDetails } = useContext<any>(MagicConnectUserContext)

  if (!userDetails?.wallet_public_address) {
    return (
      <>
        <Head>
          <title>Fuel | Connect wallet</title>
        </Head>
        <AuthenticationWrapper>
          <AuthenticationModal alwaysOpened={true} showButton={false} />
        </AuthenticationWrapper>
      </>
    )
  } else {
    router.push('/')
  }
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'auth'])),
    },
  }
}

export default LoginPage
